@tailwind base;
@tailwind components;
@tailwind utilities;

/* Add retro fonts */
@import url('https://fonts.googleapis.com/css2?family=VT323&family=Share+Tech+Mono&display=swap');

/* Custom styles for the scanning effect */
@keyframes scanline {
  0% { transform: translateY(0); }
  100% { transform: translateY(100%); }
}

.scanline {
  animation: scanline 2s linear infinite;
}